import { ICustomizations } from '../types'

const {
    NAME,
    VERSION,
    OVERLAY_TITLE,
    TITLE,
    TITLE_1,
    TITLE_2,
    TITLE_3,
    FONT_FAMILY,
    TITLE_COLOR,
    TITLE_SIZE,
    TITLE_SIZE_1,
    TITLE_SIZE_2,
    TITLE_SIZE_3,
    TITLE_BOTTOM,
    MARGIN_BOTTOM,
    FONT_COLOR,
    PRICE_FONT_COLOR,
    PRICE_COLOR,
    FONT_SIZE,
    CARD_WIDTH,
    SKINNY_WIDTH,
    SHORT_WIDTH,
    SHORT_HEIGHT,
    DEFAULT_WIDTH,
    MEMBERSHIP_TYPE,
    CODE,
    LISTINGS,
    LINK_TEXT,
    LINK_TEXT_1,
    LINK_TEXT_2,
    LINK_TEXT_3,
    LINK_SIZE,
    LINK_SIZE_1,
    LINK_SIZE_2,
    LINK_SIZE_3,
    LINK_COLOR,
    FONT_URL,
    COLORS,
    BANNER_ZID,
    BOTTOM_BANNER_ZID,
    MOBILE_BANNER_ZID,
    HIDE_BANNER_TOP,
    HIDE_BANNER_BOTTOM,
    MOBILE_ZID,
    DISPLAY_ZID,
    SKYSCRAPER_ZID,
    BIDSTREAM_STACK,
    GAM_TAG,
    GAM_TAG_DESC,
    COL1,
    COL2,
    COL3,
    COL4,
    COL5,
    COL6,
    OPTIMIZE_FCP,
    LOTAME,
    AD_UNITS,
    SCHAIN_SID,
    S2S_CONFIG,
    IDENTITY_HUB,
    IX,
    OPENX,
    MEDIANET,
    ACROSS33,
    PUBMATIC,
    YAHOOSSP,
    AMX,
    APPNEXUS,
    MEDIAFUSE,
    RUBICON,
    SOVRN,
    UNRULY,
    EQUATIV,
    SONOBI,
    ONETAG,
    BREAKPOINTS,
    AD_TYPE,
    AD_REFRESH_MAX,
    AD_REFRESH_INTERVAL,
    AD_DISABLE_DISPLAY,
    PREBID_DEFER,
    MEDIA_FUSE_WRAPPER,
    WATERMARK,
    WATERMARK_TITLE,
    CSS,
    AUDIGENT,
    NO_RESIZE,
    FOOTER,
    SEARCH,
    SEARCH_BOTTOM,
    DISABLE_DARK,
    PLACEHOLDER,
    SLUG,
    IMG_BKG,
    IAB_CATS,
    AD_GEO_BLOCK
} = process.env

export const defaultCustomizations: ICustomizations = {
    debug: false,
    browse: false,
    version: Number(VERSION),
    overlayTitle: Boolean(OVERLAY_TITLE) || false,
    hideBannerTop: Boolean(HIDE_BANNER_TOP) || false,
    hideBannerBottom: Boolean(HIDE_BANNER_BOTTOM) || false,
    name: NAME || "",
    slug: SLUG || "",
    title: TITLE || "",
    title1: TITLE_1,
    title2: TITLE_2,
    title3: TITLE_3,
    fontFamily: FONT_FAMILY || "",
    titleColor: TITLE_COLOR || "",
    titleSize: Number(TITLE_SIZE) || 28,
    titleSize1: Number(TITLE_SIZE_1),
    titleSize2: Number(TITLE_SIZE_2),
    titleSize3: Number(TITLE_SIZE_3),
    titleBottom: Boolean(TITLE_BOTTOM) || false,
    marginBottom: Number(MARGIN_BOTTOM) || 14,
    fontColor: FONT_COLOR || "#fff",
    priceFontColor: PRICE_FONT_COLOR || "",
    priceColor: PRICE_COLOR || "",
    fontSize: Number(FONT_SIZE) || 20,
    height: "400px",
    cardHeight: "250px",
    cardWidth: CARD_WIDTH || "300px",
    skinnyWidth: SKINNY_WIDTH || CARD_WIDTH || "300px",
    shortWidth: SHORT_WIDTH || "300px",
    shortHeight: SHORT_HEIGHT || "250px",
    cardPadding: "20px",
    defaultWidth: Number(DEFAULT_WIDTH) || 960,
    partnerCode: CODE || "antengo",
    membershipType: String(MEMBERSHIP_TYPE || ""),
    listings: LISTINGS || "//cache.sellwild.com/listings-img-data-sm", 
    linkText: LINK_TEXT || "", 
    linkText1: LINK_TEXT_1, 
    linkText2: LINK_TEXT_2, 
    linkText3: LINK_TEXT_3, 
    linkSize: Number(LINK_SIZE) || 28, 
    linkSize1: Number(LINK_SIZE_1), 
    linkSize2: Number(LINK_SIZE_2), 
    linkSize3: Number(LINK_SIZE_3), 
    linkColor: LINK_COLOR || "",
    fontUrl: FONT_URL || "",
    colors: typeof COLORS === 'string' ? COLORS.split(',') : Array.isArray(COLORS) ? COLORS : ["#8c77b6","#baadd3","#f3f1f7"],
    bannerZid: BANNER_ZID || 0,
    bottomBannerZid: BOTTOM_BANNER_ZID || 0,
    mobileBannerZid: MOBILE_BANNER_ZID || 0,
    mobileZids: parseNums(MOBILE_ZID),
    displayZids: parseNums(DISPLAY_ZID),
    bidstreamStack: Number(BIDSTREAM_STACK),
    skyscraperZid: SKYSCRAPER_ZID || 0,
    gamTag: GAM_TAG || '',
    gamTagDesc: GAM_TAG_DESC || '',
    col1: COL1 || '',
    col2: COL2 || '',
    col3: COL3 || '',
    col4: COL4 || '',
    col5: COL5 || '',
    col6: COL6 || '',
    optimizeFcp: Boolean(OPTIMIZE_FCP) || false,
    lotame: Boolean(LOTAME) || false,
    audigent: Boolean(AUDIGENT) || false,
    adUnits: AD_UNITS || '',
    schainSid: SCHAIN_SID || '',
    s2sConfig: S2S_CONFIG || '',
    ix: parseValue(IX),
    openx: parseValue(OPENX),
    medianet: parseValue(MEDIANET),
    across33: parseValue(ACROSS33),
    pubmatic: parseValue(PUBMATIC),
    yahoossp: parseValue(YAHOOSSP),
    amx: parseValue(AMX),
    appnexus: parseValue(APPNEXUS),
    mediafuse: parseValue(MEDIAFUSE),
    rubicon: parseValue(RUBICON),
    sovrn: parseValue(SOVRN),
    unruly: parseValue(UNRULY),
    equativ: parseValue(EQUATIV),
    sonobi: parseValue(SONOBI),
    onetag: parseValue(ONETAG),
    adGeoBlock: parseValue(AD_GEO_BLOCK),
    breakpoints: parseValue(BREAKPOINTS),
    adType: AD_TYPE || '',
    adRefreshMax: Number(AD_REFRESH_MAX) || 0,
    adRefreshInterval: Number(AD_REFRESH_INTERVAL) || 0,
    adDisableDisplay: Boolean(AD_DISABLE_DISPLAY),
    identityHub: Boolean(IDENTITY_HUB) || false,
    prebidDefer: Number(PREBID_DEFER) || 0,
    mediaFuseWrapper: MEDIA_FUSE_WRAPPER || '',
    watermark: Boolean(WATERMARK),
    watermarkTitle: WATERMARK_TITLE || 'These for sale listings + ads are from the Sellwild Marketplace. Click here to sell your own items (featured here & on other notable sites w/ millions of users) for FREE',
    css: CSS || '',
    noResize: Boolean(NO_RESIZE) || false,
    footer: FOOTER || '',
    search: SEARCH || '',
    searchBottom: Boolean(SEARCH_BOTTOM) || false,
    disableDark: Boolean(DISABLE_DARK) || false,
    placeholder: PLACEHOLDER || '',
    imgBkg: Boolean(IMG_BKG) || false,
    iabCats: parseArray(IAB_CATS)
}

type CustomizationMap = { [key: string]: keyof ICustomizations }
const customizationMap: CustomizationMap = {
    'version': 'version',
    'slug': 'slug',
    'name': 'name',
    'title': 'title',
    'title1': 'title1',
    'title2': 'title2',
    'title3': 'title3',
    'debug': 'debug',
    'height': 'height',
    'cardHeight': 'cardHeight',
    'card-height': 'cardHeight',
    'cardWidth': 'cardWidth',
    'card-width': 'cardWidth',
    'skinnyWidth': 'skinnyWidth',
    'skinny-width': 'skinnyWidth',
    'shortWidth': 'shortWidth',
    'short-width': 'shortWidth',
    'shortHeight': 'shortHeight',
    'short-height': 'shortHeight',
    'cardPadding': 'cardPadding',
    'card-padding': 'cardPadding',
    'padding': 'cardPadding',
    'partnerCode': 'partnerCode',
    'partner-code': 'partnerCode',
    'overlayTitle': 'overlayTitle',
    'overlayTitles': 'overlayTitle',
    'overlay-title': 'overlayTitle',
    'overlay-titles': 'overlayTitle',
    'overlay': 'overlayTitle',
    'partner': 'partnerCode',
    'browse': 'browse',
    'code': 'partnerCode',
    'membershipType': 'membershipType',
    'membership-type': 'membershipType',
    'fontUrl': 'fontUrl',
    'font-url': 'fontUrl',
    'font': 'fontUrl',
    'fontSize': 'fontSize',
    'font-size': 'fontSize',
    'fontColor': 'fontColor',
    'font-color': 'fontColor',
    'hide-banner-top': 'hideBannerTop',
    'hide-banner-bottom': 'hideBannerBottom',
    'hideBannerTop': 'hideBannerTop',
    'hideBannerBottom': 'hideBannerBottom',
    'price-font-color': 'priceFontColor',
    'price-color': 'priceColor',
    'priceFontColor': 'priceFontColor',
    'priceColor': 'priceColor',
    'fontFamily': 'fontFamily',
    'font-family': 'fontFamily',
    'titleSize': 'titleSize',
    'title-size': 'titleSize',
    'titleSize1': 'titleSize1',
    'title-size1': 'titleSize1',
    'titleSize2': 'titleSize2',
    'title-size2': 'titleSize2',
    'titleSize3': 'titleSize3',
    'title-size3': 'titleSize3',
    'titleColor': 'titleColor',
    'title-color': 'titleColor',
    'title-bottom': 'titleBottom',
    'titlebottom': 'titleBottom',
    'titleBottom': 'titleBottom',
    'linkText': 'linkText',
    'link-text': 'linkText',
    'linkSize': 'linkSize',
    'link-size': 'linkSize',
    'linkText1': 'linkText1',
    'link-text1': 'linkText1',
    'linkSize1': 'linkSize1',
    'link-size1': 'linkSize1',
    'linkText2': 'linkText2',
    'link-text2': 'linkText2',
    'linkSize2': 'linkSize2',
    'link-size2': 'linkSize2',
    'linkText3': 'linkText3',
    'link-text3': 'linkText3',
    'linkSize3': 'linkSize3',
    'link-size3': 'linkSize3',
    'linkColor': 'linkColor',
    'link-color': 'linkColor',
    'marginBottom': 'marginBottom',
    'margin-bottom': 'marginBottom',
    'colors': 'colors',
    'color': 'colors',
    'listings': 'listings',
    bannerZid: 'bannerZid',
    'banner-zid': 'bannerZid',
    'banner': 'bannerZid',
    bottomBannerZid: 'bottomBannerZid',
    mobileBannerZid: 'mobileBannerZid',
    'bottom-banner-zid': 'bottomBannerZid',
    'mobile-banner-zid': 'mobileBannerZid',
    'bottom-banner': 'bottomBannerZid',
    mobileZid: 'mobileZids',
    mobileZids: 'mobileZids',
    'mobile-zid': 'mobileZids',
    'mobile-zids': 'mobileZids',
    mobile: 'mobileZids',
    mobiles: 'mobileZids',
    displayZid: 'displayZids',
    displayZids: 'displayZids',
    'display-zid': 'displayZids',
    'display-zids': 'displayZids',
    display: 'displayZids',
    displays: 'displayZids',
    stack: 'bidstreamStack',
    bidstreamStack: 'bidstreamStack',
    'bidstream-stack': 'bidstreamStack',
    skyscraperZid: 'skyscraperZid',
    'skyscraper-zid': 'skyscraperZid',
    'skyscraper': 'skyscraperZid',
    'gam-tag': 'gamTag',
    'gamTag': 'gamTag',
    'gamtag': 'gamTag',
    'gam-tag-desc': 'gamTagDesc',
    'gamTagDesc': 'gamTagDesc',
    'gamtagdesc': 'gamTagDesc',
    'adUnits': 'adUnits',
    'ad-units': 'adUnits',
    'adunits': 'adUnits',
    'schainSid': 'schainSid',
    'schain-sid': 'schainSid',
    'schainsid': 'schainSid',
    's2sConfig': 's2sConfig',
    's2s-config': 's2sConfig',
    's2sconfig': 's2sConfig',
    'identityHub': 'identityHub',
    'identityhub': 'identityHub',
    'identity-hub': 'identityHub',
    'ix': 'ix',
    'across33': 'across33',
    'openx': 'openx',
    'medianet': 'medianet',
    'pubmatic': 'pubmatic',
    'yahoossp': 'yahoossp',
    'amx': 'amx',
    'appnexus': 'appnexus',
    'mediafuse': 'mediafuse',
    'rubicon': 'rubicon',
    'sovrn': 'sovrn',
    'unruly': 'unruly',
    'equativ': 'equativ',
    'sonobi': 'sonobi',
    'onetag': 'onetag',
    'breakpoints': 'breakpoints',
    'adType': 'adType',
    'ad-type': 'adType',
    'adtype': 'adType',
    'ad-geo-block': 'adGeoBlock',
    'adgeoblock': 'adGeoBlock',
    'adGeoBlock': 'adGeoBlock',
    'adRefreshInterval': 'adRefreshInterval',
    'ad-refresh-interval': 'adRefreshInterval',
    'adRefreshMax': 'adRefreshMax',
    'ad-refresh-max': 'adRefreshMax',
    'adDisableDisplay': 'adDisableDisplay',
    'ad-disable-display': 'adDisableDisplay',
    'addisabledisplay': 'adDisableDisplay',
    'defer': 'prebidDefer',
    'prebid-defer': 'prebidDefer',
    'prebidDefer': 'prebidDefer',
    'prebiddefer': 'prebidDefer',
    'media-fuse-wrapper': 'mediaFuseWrapper',
    'mediaFuseWrapper': 'mediaFuseWrapper',
    col1: 'col1',
    col2: 'col2',
    col3: 'col3',
    col4: 'col4',
    col5: 'col5',
    col6: 'col6',
    optimizeFcp: 'optimizeFcp',
    'optimize-fcp': 'optimizeFcp',
    'lotame': 'lotame',
    'audigent': 'audigent',
    'noResize': 'noResize',
    'no-resize': 'noResize',
    watermark: 'watermark',
    watermarkTitle: 'watermarkTitle',
    'watermark-title': 'watermarkTitle',
    'css': 'css',
    footer: 'footer',
    search: 'search',
    'search-bottom': 'searchBottom',
    searchBottom: 'searchBottom',
    disableDark: 'disableDark',
    'disable-dark': 'disableDark',
    placeholder: 'placeholder',
    imgBkg: 'imgBkg',
    'img-bkg': 'imgBkg',
    'iab-cats': 'iabCats',
    'iabcats': 'iabCats',
    'iabCats': 'iabCats',
}

function parseArray (value: any) {
    if (Array.isArray(value)) return value
    return parseValue((value || '').replace('List ', ''))
}
function parseValue (value: string|undefined) {
    if (!value) return value
    try {
        return JSON.parse(value)
    } catch (e) {
        return value
    }
}

function parseNums (value = '') {
    return String(value || '').split(',').map(v => Number(v.trim()) || v)
}

let partnerCode = 'antengo'
export const getPartnerCode = () => {
    return partnerCode
}

export const withCustomizationsFromUrl = (location: Window['location'], initial: ICustomizations): ICustomizations => {
    const url = location.href.split("?");
    if (url[1]) {
        return url[1].split("&")
        .map(val => val.split("="))
        .reduce((acc, [c, val]) => {
            const key = customizationMap[c]
            if (acc.hasOwnProperty(key) && val && val !== 'undefined') {
                if (key === 'listings') {
                    (acc as any)[key] = decodeURIComponent(val)
                } else if (key === 'fontFamily') {
                    (acc as any)[key] = decodeURIComponent(val)
                } else if (key === 'fontUrl') {
                    (acc as any)[key] = decodeURIComponent(val)
                } else if (key === 'fontColor') {
                    (acc as any)[key] = decodeURIComponent(val)
                } else if (key === 'titleColor') {
                    (acc as any)[key] = decodeURIComponent(val)
                } else if (key === 'linkColor') {
                    (acc as any)[key] = decodeURIComponent(val)
                } else if (key === 'priceColor') {
                    (acc as any)[key] = decodeURIComponent(val)
                } else if (key === 'priceFontColor') {
                    (acc as any)[key] = decodeURIComponent(val)
                } else if (key === 'colors') {
                    (acc as any)[key] = decodeURIComponent(val).split(',').filter(v => v)
                } else if (key === 'gamTag') {
                    (acc as any)[key] = decodeURIComponent(val)
                } else if (key === 'gamTagDesc') {
                    (acc as any)[key] = decodeURIComponent(val)
                } else if (key === 'adUnits') {
                    (acc as any)[key] = decodeURIComponent(val)
                } else if (key === 'mediaFuseWrapper') {
                    (acc as any)[key] = decodeURIComponent(val)
                } else if (key === 'css') {
                    (acc as any)[key] = decodeURIComponent(val)
                } else if (key === 'displayZids') {
                    (acc as any)[key] = parseNums(decodeURIComponent(val))
                } else if (key === 'mobileZids') {
                    (acc as any)[key] = parseNums(decodeURIComponent(val))
                } else if (key === 'yahoossp') {
                    (acc as any)[key] = parseValue(decodeURIComponent(val))
                } else if (key === 'pubmatic') {
                    (acc as any)[key] = parseValue(decodeURIComponent(val))
                } else if (key === 'ix') {
                    (acc as any)[key] = parseValue(decodeURIComponent(val))
                } else if (key === 'amx') {
                    (acc as any)[key] = parseValue(decodeURIComponent(val))
                } else if (key === 'appnexus') {
                    (acc as any)[key] = parseValue(decodeURIComponent(val))
                } else if (key === 'mediafuse') {
                    (acc as any)[key] = parseValue(decodeURIComponent(val))
                } else if (key === 'rubicon') {
                    (acc as any)[key] = parseValue(decodeURIComponent(val))
                } else if (key === 'sovrn') {
                    (acc as any)[key] = parseValue(decodeURIComponent(val))
                } else if (key === 'unruly') {
                    (acc as any)[key] = parseValue(decodeURIComponent(val))
                } else if (key === 'equativ') {
                    (acc as any)[key] = parseValue(decodeURIComponent(val))
                } else if (key === 'sonobi') {
                    (acc as any)[key] = parseValue(decodeURIComponent(val))
                } else if (key === 'onetag') {
                    (acc as any)[key] = parseValue(decodeURIComponent(val))
                } else if (key === 'adGeoBlock') {
                    (acc as any)[key] = parseValue(decodeURIComponent(val))
                } else if (key === 'breakpoints') {
                    (acc as any)[key] = parseValue(decodeURIComponent(val))
                } else if (key === 'iabCats') {
                    (acc as any)[key] = parseArray(decodeURIComponent(val))
                } else {
                    (acc as any)[key] = parseValue(val) 
                }
            }
            return acc
        }, initial)
    }
    return initial
}

export const withCustomizationsFromElement = (element: Element, initial: ICustomizations): ICustomizations => {
    return Object.keys(customizationMap)
    .reduce((acc, c) => {
        const value = element.getAttribute(c)
        if (!value) return acc;
        if (customizationMap[c] === 'colors') {
            (acc as any)[customizationMap[c]] = value.split(',').filter(v => v)
        } else {
            (acc as any)[customizationMap[c]] = parseValue(value)
        }
        return acc
    }, initial)
}

export const getCustomizations = (location: Window['location'], element: Element): ICustomizations => {
    const fromElement = withCustomizationsFromElement(
        element,
        defaultCustomizations
    )
    const customizations = withCustomizationsFromUrl(
        location,
        fromElement
    )
    if (customizations.partnerCode) {
        partnerCode = customizations.partnerCode
    }
    return customizations
}

const browseUrl = 'https://browse.sellwild.com/itemDetail'
const legacyUrl = 'https://sellwild.com/product'

const random = Math.random()

export const getListingUrl = (listing: {id: string; url: string; dataSourceId: string; remote_url?: string}, config: any, theme: ICustomizations) => {
    const qs = `?p=${theme.partnerCode}&utm_source=${theme.partnerCode}`
    let url = ''
    if (listing.url) {
        return listing.url + qs
    } else if (String(listing.dataSourceId) === '31' && listing.remote_url) {
        url = listing.remote_url + qs
    } else if (config && typeof config.browse === "number") {
        url = ((random > config.browse) ? legacyUrl : browseUrl)
    } else {
        url = theme.browse ? browseUrl : legacyUrl
    }
    return `${url}/${listing.id}/${qs}`
}

export const getMainUrl = (theme: ICustomizations, path: string, content: string) => {
    const qs = `?p=${theme.partnerCode}&utm_source=${theme.partnerCode}&utm_content=${content}`
    return `https://sellwild.com/${path}${qs}`
}

export function makeCustomizationController (element: HTMLElement, search: URLSearchParams) {
    function getValue (name: string, def: string = '') {
      return element.getAttribute(name) || search.get(name) || def
    }
  
    function getNumber (name: string, def: number = 0) {
      const value = getValue(name)
      return Number(value) || def
    }
  
    function getObject (name: string, def: unknown) {
      const value = getValue(name)
      try {
        return JSON.parse(value)
      } catch (err) {
        return def
      }
    }
  
    return {
      getValue,
      getNumber,
      getObject,
    }
  }